import React, {useState} from 'react';

const ThemeControl = () => {

    const setDark = () => {
        document.documentElement.setAttribute("data-theme", "dark");
    }

    const setHighContrast = () => {
        document.documentElement.setAttribute("data-theme", "high-contrast");
    }

    const storedTheme = localStorage.getItem("theme");

    const prefersDark =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches;

    const defaultDark =
        storedTheme === "dark" || (storedTheme === null && prefersDark);

    const defaultHighContrast =
        storedTheme === "high-contrast";

    if (defaultDark) {
        setDark();
    }

    if (defaultHighContrast) {
        setHighContrast();
    }
}

export default ThemeControl;