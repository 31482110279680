import {useNavigate} from 'react-router-dom';

export const onModalOpen = (val) => (dispatch) => {
    dispatch({
        type: 'MODAL_OPEN',
        payload: val
    })
}
export const onChangeDarkMode = (val) => (dispatch) => {
    localStorage.setItem("theme", val);
    if (val === 'dark') {
        window.document.children[0].setAttribute('data-theme', 'dark');
    } else if (val === 'high-contrast') {
    } else {
        window.document.children[0].setAttribute('data-theme', 'light')
    }
    dispatch({
        type: 'DARK_MODE',
        payload: val
    })
    dispatch({
        type: 'HIGH_CONTRAST',
        payload: val
    })
}
export const onChangeHighcontrast = (val) => (dispatch) => {
    localStorage.setItem("theme", val);
    if (val === 'high-contrast') {
        window.document.children[0].setAttribute('data-theme', 'high-contrast');
    } else if (val === 'dark') {
        window.document.children[0].setAttribute('data-theme', 'light')
    } else {
        window.document.children[0].setAttribute('data-theme', 'light')
    }
    dispatch({
        type: 'HIGH_CONTRAST',
        payload: val
    })
    dispatch({
        type: 'DARK_MODE',
        payload: val
    })
}

export const onChangeThemeColor = (val) => (dispatch) => {
    console.log(val);
    localStorage.setItem("themeColor", val);
    if (val !== undefined && val !== null) {
        var theme = document.getElementById('cryptoon-layout');
        theme.className = `theme-${val}`
    }

    dispatch({
        type: 'THEME_COLOR',
        payload: val
    })
}

export const onMenuData = (val) => (dispatch) => {
    dispatch({
        type: 'MENUDATA',
        payload: val
    })
}

